import React, { useState, useEffect, lazy, Suspense } from 'react';
import axios from 'axios';
import './matches.css';
const Container = lazy(() => import('react-bootstrap/Container'));
const Row = lazy(() => import('react-bootstrap/Row'));
const Col = lazy(() => import('react-bootstrap/Col'));
const Tab = lazy(() => import('react-bootstrap/Tab'));
const Tabs = lazy(() => import('react-bootstrap/Tabs'));
export default function Index() {
    const [livedata, setLivedata] = useState([]);
    const [resentdata, setResentdata] = useState([]);
    const [loading, setLoading] = useState(true);
    const getLiveData = async () => {
        try {
            const response = await axios.get('https://admin.enigmamotions.com/api/auth/get-live-match/live');
            setLivedata(response.data.data); 
        } catch (error) {
            console.error('Error fetching live data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getResentData = async () => {
        try {
            const response = await axios.get('https://admin.enigmamotions.com/api/auth/get-live-match');
            setResentdata(response.data.data); 
        } catch (error) {
            console.error('Error fetching resent live data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLiveData();
        getResentData();
        const interval = setInterval(() => {
            getLiveData(); 
        }, 30000);
        return () => clearInterval(interval);
    }, []);


    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Container className="matches-tabs">
                <Row>
                    <Col>
                        <Tabs defaultActiveKey="live" id="justify-tab-example" className="mb-1" justify>
                            <Tab eventKey="live" title="Live">
                                <Row className="mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1">
                                    {loading && <div>Loading live data...</div>}
                                    {!loading && livedata.length > 0 ? (
                                        livedata.map((match, index) => (
                                            <Col lg={3} md={6} key={index}>
                                                <div className="bg-white p-3">
                                                    <h6 className="">{match.clubName}</h6>
                                                    <Row>
                                                        <Col>
                                                            <img alt="flag" src="img/new-zealand.png" />
                                                            &nbsp;&nbsp;
                                                            <span>{match.player1Name}</span>
                                                        </Col>
                                                        <Col>
                                                            <span>{match.player1Score}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <img alt="flag" src="img/armenia.png" />
                                                            &nbsp;&nbsp;
                                                            <span><b>{match.player2Name}</b></span>
                                                        </Col>
                                                        <Col>
                                                            <span><b>{match.player2Score}</b></span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <span className="text-primary">{match.address}</span>
                                                        </Col>
                                                        <Col>
                                                            <span><b><a href={match.youtube_url} target="_blank"> Play</a></b></span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        ))
                                    ) : (
                                        !loading && <div>No live Matches available</div>
                                    )}
                                </Row>
                            </Tab>
                            <Tab eventKey="upcoming" title="Recent">
                                <Row className="mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1">
                                {loading && <div>Loading live data...</div>}
                                    {!loading && resentdata.length > 0 ? (
                                        resentdata.map((match, index) => (
                                            <Col lg={3} md={6} key={index}>
                                                <div className="bg-white p-3">
                                                    <h6 className="">{match.clubName}</h6>
                                                    <Row>
                                                        <Col>
                                                            <img alt="flag" src="img/new-zealand.png" />
                                                            &nbsp;&nbsp;
                                                            <span>{match.player1Name}</span>
                                                        </Col>
                                                        <Col>
                                                            <span>{match.player1Score}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <img alt="flag" src="img/armenia.png" />
                                                            &nbsp;&nbsp;
                                                            <span><b>{match.player2Name}</b></span>
                                                        </Col>
                                                        <Col>
                                                            <span><b>{match.player2Score}</b></span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <span className="text-primary">{match.address}</span>
                                                        </Col>
                                                        <Col>
                                                            <span><b><a href={match.youtube_url} target="_blank"> Play</a></b></span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        ))
                                    ) : (
                                        !loading &&  <div>Recent Matches Content Here</div>
                                    )}
                                  
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </Suspense>
    );
}
